.modalContainer {
  max-height: 90vh;
  background-color: #ffffff;
}

.modal-title {
  /* font-size: 1.6vw; */
  color: #ffffff;
}

.modal-header {
  background-color: lightslategray;
}

.modal-body {
  overflow-y: auto;
}

.modal-footer {
  background-color: #4c758a;
  flex-direction: row-reverse;
  justify-content: space-between;
}
