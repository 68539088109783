.card-header {
  color: #153d67;
}

.error-class {
  text-align: center;
  color: #5d5c5c;
}

.error-class h1 {
  color: #d00b05;
}

.btn-secondary:disabled {
  cursor: not-allowed !important;
}

.error-class img {
  width: 20%;
}

.folder {
  color: burlywood;
}

.file {
  color: grey;
}

.loading-class {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
}

.loading-class img {
  width: 32%;
}

.sigma-cell {
  margin: 1px;
  border: 2px solid #aaa;
  background-color: #fff;
  color: #1d1c1c;
  text-align: center;
}

.btn:disabled {
  cursor: not-allowed;
}

.div-table {
  display: grid;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  border: 2px solid #e8eaf5;
  white-space: nowrap;
}

.select-row {
  background-color: #153d67 !important;
  color: #fff;
}

.btn {
  font-size: small;
}
.form-row {
  align-items: baseline;
}

.home svg {
  color: rgb(87, 86, 86);
  /* margin-left: 1rem; */
  margin-right: 0.5rem;
}

.home h1 {
  color: #103257;
}

.home h4 {
  color: #103257;
}

.home li {
  color: rgb(10, 11, 66);
}

.home-icon {
  position: absolute;
  opacity: 0.2;
  font-size: 22rem;
  bottom: 7%;
  right: 2%;
  color: #103257 !important;
}
.home-icon1 {
  position: absolute;
  opacity: 0.2;
  font-size: 8rem;
  bottom: 8%;
  right: 28%;
  color: #1d1c1c !important;
}

.home-info {
  background: url("../image/bg4.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  max-height: 75vh;
  overflow-y: scroll;
}

.portal p {
  color: #103257 !important;
}

.service-qualification-fields .radio-buttons {
  margin: 20px 0 10px 0;
}

.service-qualification-fields .input {
  padding-left: 0;
}

.service-qualification-fields label::before,
.service-qualification-fields label::after {
  top: 0;
}

/** Styles for Staging inventory page **/
.search-id-content {
  margin-left: 20px;
  margin-right: 20px;
}

.search-id-content label::before,
.search-id-content label::after {
  top: 0;
}

.text-content textarea {
  margin-bottom: 20px; 
  margin-top: 20px;
}

.release a {
  color: blue;
  text-decoration: none;
  font-size: 20px;
}
