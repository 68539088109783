@font-face {
  font-family: "nuuday";
  font-style: normal;
  font-weight: 400;
  src: url("../font/Nuu-Regular.ttf"); /* IE9 Compat Modes */
  src: local("nuuday"), local("nuuday"),
    url("../font/Nuu-Regular.ttf?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../font/Nuu-Regular.ttf?") format("truetype"),
    /* Safari, Android, iOS */ url("../font/Nuu-Regular.ttf?#OpenSans")
      format("svg"); /* Legacy iOS */
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
body {
  font-family: "nuuday", "Poppins", sans-serif;
  background: #fafafa;
}
p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10px;
  background: #4c758a !important;
  border: none;
  border-radius: 0;
  margin-bottom: 1%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

i,
span {
  display: inline-block;
  margin-left: 10px;
}

.heading {
  margin-left: 10px;
}

.logo-image {
  background-image: url(../../asset/image/nuuday-1.png);
  height: 50px;
  width: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  margin-left: 22px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.bg1 {
  /* background-image: url('../../asset/bg2.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat; */
  background-color: #a5c2d047;
  font-family: "nuuday", "Numans", sans-serif;
  font-size: small;
}

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #4c758a;
  color: #ffffff;
  transition: all 0.3s;
}

#sidebar.active {
  min-width: 94px;
  max-width: 94px;
  text-align: center;
}

#sidebar.active .sidebar-header h2 {
  display: none;
}

#sidebar.active .sidebar-header strong {
  display: block;
}

#sidebar ul li {
  border-bottom: 1px solid #4c758a;
}

#sidebar ul li a {
  text-align: left;
}
#sidebar span {
  display: inline-block;
  margin-left: 10px;
  text-shadow: none;
}

#sidebar.active span {
  display: none;
}

#sidebar.active ul li a {
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em;
}

#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px;
}

#sidebar.active ul ul a {
  padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

#sidebar .sidebar-header {
  margin-top: 20px;
  padding: 20px;
  background: #5c879c;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.5em;
}

#sidebar ul.components {
  /* padding: 2px 0; */
  border-bottom: 1px solid #5c879c;
  background-color: #5c879cb5;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1em !important;
  display: block;
}

#sidebar ul li a:hover {
  color: #7386d5;
  background: #fff;
}

#sidebar ul li a i {
  margin-right: 10px;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
}

a[data-toggle="collapse"] {
  position: relative;
}
.dropdown-toggle {
  white-space: break-spaces;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 20px !important;
  background: #729baf;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #17a2b8 !important;
  color: #fff !important;
  text-align: center !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;
  }
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  #sidebar.active {
    margin-left: 0 !important;
  }
  #sidebar .sidebar-header h2 {
    display: none;
  }
  #sidebar .sidebar-header strong {
    display: block;
  }
  #sidebar ul li a {
    padding: 20px 10px;
  }
  #sidebar ul li a span {
    font-size: 0.85em;
  }
  #sidebar ul li a i {
    margin-right: 0;
    display: block;
  }
  #sidebar ul ul a {
    padding: 10px !important;
  }
  #sidebar ul li a i {
    font-size: 1.3em;
  }
  #sidebar {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
  #navbarSupportedContent {
    text-align: end;
  }
}

.navLink-active {
  background-color: #e6eef2;
  color: #0a4968 !important;
}

.linkClass {
  color: #00050a;
}
